import React, { useCallback, useState, useEffect } from "react";
import Button from "./Button";
import LanguageDropdown from "./Language";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for menu open/close

  const onLogoClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameSection']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText34Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='section2']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText35Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='section1']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText36Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='container1']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText37Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='container']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText38Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='container-number']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const checkIsMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 640); // Assuming 640px is the breakpoint for phones
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

    const handleMenuOpen = useCallback(() => {
      const menu = document.querySelector(".menu");
      const hamburger = document.querySelector(".hamburger");
      const hamburgerCancel = document.querySelector(".hamburger-cancel");
      const headerCall = document.querySelector(".header-call");

      if (menu && hamburger && hamburgerCancel) {
        menu.classList.add("animate__animated", "animate__fadeIn");
        menu.style.display = "flex";
        hamburger.classList.add("animate__animated", "animate__fadeOut");
        hamburger.style.display = "none";
        hamburgerCancel.classList.add("animate__animated", "animate__fadeIn");
        hamburgerCancel.style.display = "block";
        headerCall.style.display = "none";

        document.body.style.overflow = "hidden";
      }
    }, []);

    const handleMenuClose = useCallback(() => {
      const menu = document.querySelector(".menu");
      const hamburger = document.querySelector(".hamburger");
      const hamburgerCancel = document.querySelector(".hamburger-cancel");
      const headerCall = document.querySelector(".header-call");

      if (menu && hamburger && hamburgerCancel) {
        menu.classList.remove("animate__animated", "animate__fadeIn");
        menu.style.display = "none";
        hamburger.classList.remove("animate__animated", "animate__fadeOut");
        hamburger.style.display = "block";
        hamburgerCancel.classList.remove("animate__animated", "animate__fadeIn");
        hamburgerCancel.style.display = "none";
        headerCall.style.display = "block";

        document.body.style.overflow = "auto";
      }
    }, []);

  return (
    <header className="self-stretch bg-white shadow-[0px_4px_16px_rgba(0,_0,_0,_0.16)] flex flex-row items-center justify-between py-6 px-[5.25%] box-border gap-[10%] top-[0] z-[99] sticky max-w-full lg:gap-[116px] sm:gap-[10px] mq450:gap-[29px] mq750:gap-[58px]">      
      <div className="w-[179px] flex flex-col items-start justify-end pt-0 px-0 pb-[3.7px] box-border">
        <img
          className="w-[110.2px] h-[34.3px] relative cursor-pointer"
          loading="lazy"
          alt=""
          src="/logo.svg"
          onClick={onLogoClick}
        />
      </div>
      <nav className="m-0 w-[376px] flex flex-col items-center justify-end pt-0 px-0 pb-1 box-border max-w-full mq750:hidden">
        <nav className="m-0 self-stretch flex flex-row items-center justify-between gap-[20px] text-center text-base text-darkslategray font-montserrat">
          <div
            className="relative leading-[18px] font-medium inline-block min-w-[87px] cursor-pointer"
            onClick={onText34Click}
          >
            Описание
          </div>
          <div
            className="relative leading-[18px] font-medium inline-block min-w-[43px] cursor-pointer"
            onClick={onText35Click}
          >
            Цель
          </div>
          <div
            className="relative leading-[18px] font-medium inline-block min-w-[50px] cursor-pointer"
            onClick={onText36Click}
          >
            Герои
          </div>
          <div
            className="relative leading-[18px] font-medium inline-block min-w-[62px] cursor-pointer"
            onClick={onText37Click}
          >
            Работы
          </div>
        </nav>
      </nav>
      <div className="flex items-center justify-end gap-[7.5%]">
        { isMobile ? (
          <>
            <LanguageDropdown />
            <Button />
            <div className="flex items-center px-3">
              <img
                className="w-[24px] h-[24px] cursor-pointer hamburger"
                alt=""
                src="/hamburger-menu.svg"
                onClick={handleMenuOpen}
              />
              <img
                className="w-[24px] h-[24px] cursor-pointer ml-2 hidden hamburger-cancel"
                alt=""
                src="/hamburger-menu-cancel.svg"
                onClick={handleMenuClose}
              />
            </div>
          </>
        ) : (
          <>
            <LanguageDropdown />
            <Button />
          </>
        ) }
      </div>
    </header>
  );
};

export default Header;
