import React, { useState, useEffect } from 'react';

const Numbers = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIsMobile = () => {
            const screenWidth = window.innerWidth;
            setIsMobile(screenWidth < 640); // Assuming 640px is the breakpoint for mobile screens
        };

        checkIsMobile();

        window.addEventListener('resize', checkIsMobile);

        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    return (
        <section
            className="w-[100%] flex flex-row items-start justify-center pt-0 pb-0 sm:pb-24 box-border max-w-full text-center text-9xl text-darkslategray font-montserrat mq750:box-border"
            data-scroll-to='container-number'
        >
            <div className="w-[100%] flex flex-col items-start justify-center max-w-full">
                <div className="w-[100%] flex flex-row items-start justify-center py-0 pb-10 px-5 box-border max-w-full">
                    <h2 className="m-0 w-[201px] relative text-inherit leading-[36px] font-bold font-inherit inline-block mq450:text-3xl mq450:leading-[29px]">  
                      We in Figures
                    </h2>
                </div>
                <div className="self-stretch flex flex-row flex-wrap items-start justify-center max-w-full text-21xl text-white">
                    {isMobile ? (
                        <img className="w-[100%] max-h-full overflow-hidden" loading="lazy" alt="" src="/EN/mobile-numbers.svg" />
                    ) : (
                        <img className="w-[80%] max-h-full max-w-full overflow-hidden" loading="lazy" alt="" src="/EN/numbers.svg" />
                    )}
                </div>
            </div>
        </section>
    );
};

export default Numbers;
