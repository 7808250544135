const FrameComponent = () => {
  return (
    <section
      className="self-stretch bg-ghostwhite flex flex-col items-center justify-center py-24 px-10 box-border max-w-full text-center text-9xl text-darkslategray font-montserrat mq450:py-10 mq450:box-border mq750:box-border mq1050:pt-[62px] mq1050:pb-[62px] mq1050:box-border"
      data-scroll-to="section2"
    >
      <div className="self-stretch flex flex-row items-start justify-center">
        <h2 className="m-0 w-[296px] relative text-inherit leading-[36px] pb-[36px] font-bold font-inherit inline-block mq450:text-3xl mq450:leading-[29px]">
          Fair Description
        </h2>
      </div>     
      <div className="flex flex-col items-start justify-start gap-[36px] max-w-full">
        <div className="w-[641px] flex flex-col sm:flex-row flex-wrap items-center lg:items-start justify-center gap-[24px] max-w-full text-left text-xl">
          <img
            className="h-20 w-20 relative"
            loading="lazy"
            alt=""
            src="/frame-11.svg"
          />
          <div className="flex-1 flex flex-col items-start justify-start pt-3.5 px-0 pb-0 box-border max-w-full">
            <div className="self-stretch relative leading-[26px] font-medium text-transparent !bg-clip-text [background:linear-gradient(rgba(0,_0,_0,_0.4),_rgba(0,_0,_0,_0.4)),_rgba(93,_79,_202,_0.9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-base mq450:leading-[21px]">
              Celebration awaited by more than 500 children with special needs and over two thousand citizens of the capital.
            </div>
          </div>
        </div>
        <div className="w-[883px] ml-0 lg:ml-[100px] flex flex-col sm:flex-row flex-wrap items-center lg:items-start justify-center gap-[24px] max-w-full text-left text-xl">
          <img
            className="h-20 w-20 relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/show-1.svg"
          />
          <div className="flex-1 flex flex-col items-start justify-start pt-3.5 px-0 pb-0 box-border max-w-full">
            <div className="self-stretch relative leading-[26px] font-medium text-transparent !bg-clip-text [background:linear-gradient(rgba(0,_0,_0,_0.4),_rgba(0,_0,_0,_0.4)),_rgba(5,_147,_223,_0.9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-base mq450:leading-[21px]">
              Children perform on stage with celebrities, including entertainment stars, Paralympians, and leaders in professions, who also have disabilities.
            </div>
          </div>
        </div>
        <div className="w-[511px] ml-0 lg:ml-[200px] flex flex-col sm:flex-row flex-wrap items-center sm:items-start justify-center gap-[24px] max-w-full text-left text-xl">
          <img
            className="h-20 w-20 relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/pottery-1.svg"
          />
          <div className="flex-1 flex flex-col items-start justify-start pt-3.5 px-0 pb-0 box-border max-w-full
            ">
            <div className="self-stretch relative leading-[26px] font-medium text-transparent !bg-clip-text [background:linear-gradient(rgba(0,_0,_0,_0.4),_rgba(0,_0,_0,_0.4)),_rgba(217,_6,_179,_0.9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-base mq450:leading-[21px]">
              Over 2000 works made by children and young people with special needs.
            </div>
          </div>
        </div>
        <div className="w-[738px] ml-0 lg:ml-[300px] flex flex-col sm:flex-row flex-wrap items-center lg:items-start justify-center gap-[24px] max-w-full text-left text-xl">
          <img
            className="h-20 w-20 relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/discussion-1.svg"
          />
          <div className="flex-1 flex flex-col items-start justify-start pt-3.5 px-0 pb-0 box-border">
            <div className="self-stretch relative leading-[26px] font-medium text-transparent !bg-clip-text [background:linear-gradient(rgba(0,_0,_0,_0.4),_rgba(0,_0,_0,_0.4)),_rgba(93,_79,_202,_0.9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-base mq450:leading-[21px]">
              Motivational meetings for motivation and professional orientation of children and youth of Uzbekistan with disabilities.
            </div>
          </div>
        </div>
        <div className="w-[753px] ml-0 lg:ml-[400px] flex flex-col sm:flex-row flex-wrap items-center lg:items-start justify-center gap-[24px] max-w-full text-left text-xl">
          <img
            className="h-20 w-20 relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/teaching-1.svg"
          />
          <div className="flex-1 flex flex-col items-start justify-start pt-3.5 px-0 pb-0 box-border max-w-full">
            <div className="self-stretch relative leading-[26px] font-medium text-transparent !bg-clip-text [background:linear-gradient(rgba(0,_0,_0,_0.4),_rgba(0,_0,_0,_0.4)),_rgba(5,_147,_223,_0.9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-base mq450:leading-[21px]">
              Over 10 masterclasses for children and guests, revealing professional directions and psychological elements.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent;
