import React from "react";

const LanguageDropdown = () => {
  return (
    <div className="flex gap-[10px] sm:gap-[20px] text-base sm:text-5xl language">
      <a className="flex items-center no-underline" href="/">🇷🇺<span className="text-base pl-1 text-darkslategray">Ру</span></a>
      <a className="flex items-center no-underline" href="/en/">🇺🇸<span className="text-base pl-1 text-darkslategray">En</span></a>
    </div>
  );
};

export default LanguageDropdown;
