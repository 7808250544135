import React, { useState, useEffect } from 'react';

const Component2 = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const checkIsMobile = () => {
        const screenWidth = window.innerWidth;
        setIsMobile(screenWidth < 640); // Assuming 640px is the breakpoint for mobile screens
      };
  
      checkIsMobile();
  
      window.addEventListener('resize', checkIsMobile);
  
      return () => {
        window.removeEventListener('resize', checkIsMobile);
      };
    }, []);

    return (
        <section className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-24 box-border max-w-full text-center text-9xl text-darkslategray font-montserrat lg:pb-10 lg:box-border mq750:pb-[26px] mq750:box-border">
            <div className="self-stretch flex flex-col items-center justify-start py-24 px-5 box-border max-w-full mq450:pt-10 mq450:pb-10 mq450:box-border mq1050:pt-[62px] mq1050:pb-[62px] mq1050:box-border" data-scroll-to="container1">
                <div className="w-[873px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
                    <h2 className="m-0 w-[540px] relative text-inherit leading-[36px] font-bold font-inherit inline-block mq450:text-3xl mq450:leading-[29px]">
                        Heroes of the charity fair
                    </h2>
                </div>
                {isMobile ? (
                    <img
                    className="w-[100%] relative max-h-full overflow-hidden max-w-full"
                    loading="lazy"
                    alt=""
                    src="/EN/mobile-mask-group.svg" // Change the source for mobile screens
                    />
                ) : (
                    <img
                    className="w-[873px] relative max-h-full overflow-hidden max-w-full"
                    loading="lazy"
                    alt=""
                    src="/EN/mask-group.svg" // Default source for non-mobile screens
                    />
                )}            
                </div>
            <div className="self-stretch bg-ghostwhite flex flex-col items-center justify-start pt-8 pb-5 sm:py-[100px] px-0 sm:px-5 box-border gap-0 sm:gap-[32px] max-w-full z-[1] lg:box-border mq750:gap-[16px] mq750:box-border" data-scroll-to="container">
                <div className="w-[100%] sm:w-[994px] flex flex-row items-start justify-center max-w-full">
                    <h2 className="m-0 w-[210px] relative text-inherit leading-[36px] font-bold font-inherit inline-block mq450:text-3xl mq450:leading-[29px]">
                        Children's works
                    </h2>
                </div>
                {isMobile ? (
                    <img
                    className="w-[100%] relative max-h-full overflow-hidden max-w-full"
                    loading="lazy"
                    alt=""
                    src="/mobile-frame.png" // Change the source for mobile screens
                    />
                ) : (
                    <img
                    className="w-[994px] relative max-h-full overflow-hidden max-w-full"
                    loading="lazy"
                    alt=""
                    src="/frame.png" // Default source for non-mobile screens
                    />
                )}            
            </div>
        </section>
    );
};

export default Component2;
