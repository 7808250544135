import { useCallback, useState, useEffect } from "react";
import { Button as MuiButton } from "@mui/material";

const Button = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 640); // Assuming 640px is the breakpoint for phones
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  const onButtonClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='section']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <MuiButton
      className="h-[42px] cursor-pointer header-call items-center text-nowrap"
      disableElevation={true}
      variant="contained"
      sx={{
        textTransform: "none",
        color: "#fff",
        fontSize: "16",
        background: "#d906b3",
        borderRadius: "8px",
        "&:hover": { background: "#d906b3" },
        maxWidth: 179,
        height: 42,
      }}
      onClick={onButtonClick}
    >
      {isMobile ? (
        <img src="/phone.svg" alt="Phone" style={{ width: "24px", height: "24px" }} />
      ) : (
        "Contact us"
      )}
    </MuiButton>
  );
};

export default Button;
