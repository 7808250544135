import React, { useState, useCallback } from "react";

const Hamburger = () => {
    const onLogoClick = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='frameSection']");
        if (anchor) {
            anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }

        const menu = document.querySelector(".menu");
        const hamburger = document.querySelector(".hamburger");
        const hamburgerCancel = document.querySelector(".hamburger-cancel");
        const headerCall = document.querySelector(".header-call");
  
        if (menu && hamburger && hamburgerCancel) {
          menu.classList.remove("animate__animated", "animate__fadeIn");
          menu.style.display = "none";
          hamburger.classList.remove("animate__animated", "animate__fadeOut");
          hamburger.style.display = "block";
          hamburgerCancel.classList.remove("animate__animated", "animate__fadeIn");
          hamburgerCancel.style.display = "none";
          headerCall.style.display = "block";
  
          document.body.style.overflow = "auto";
        }
    }, []);

    const onText34Click = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='section2']");
        if (anchor) {
            anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }

        const menu = document.querySelector(".menu");
        const hamburger = document.querySelector(".hamburger");
        const hamburgerCancel = document.querySelector(".hamburger-cancel");
        const headerCall = document.querySelector(".header-call");
  
        if (menu && hamburger && hamburgerCancel) {
          menu.classList.remove("animate__animated", "animate__fadeIn");
          menu.style.display = "none";
          hamburger.classList.remove("animate__animated", "animate__fadeOut");
          hamburger.style.display = "block";
          hamburgerCancel.classList.remove("animate__animated", "animate__fadeIn");
          hamburgerCancel.style.display = "none";
          headerCall.style.display = "block";
  
          document.body.style.overflow = "auto";
        }
    }, []);

    const onText35Click = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='section1']");
        if (anchor) {
            anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }

        const menu = document.querySelector(".menu");
        const hamburger = document.querySelector(".hamburger");
        const hamburgerCancel = document.querySelector(".hamburger-cancel");
        const headerCall = document.querySelector(".header-call");
  
        if (menu && hamburger && hamburgerCancel) {
          menu.classList.remove("animate__animated", "animate__fadeIn");
          menu.style.display = "none";
          hamburger.classList.remove("animate__animated", "animate__fadeOut");
          hamburger.style.display = "block";
          hamburgerCancel.classList.remove("animate__animated", "animate__fadeIn");
          hamburgerCancel.style.display = "none";
          headerCall.style.display = "block";
  
          document.body.style.overflow = "auto";
        }
    }, []);

    const onText36Click = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='container1']");
        if (anchor) {
            anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }

        const menu = document.querySelector(".menu");
        const hamburger = document.querySelector(".hamburger");
        const hamburgerCancel = document.querySelector(".hamburger-cancel");
        const headerCall = document.querySelector(".header-call");
  
        if (menu && hamburger && hamburgerCancel) {
          menu.classList.remove("animate__animated", "animate__fadeIn");
          menu.style.display = "none";
          hamburger.classList.remove("animate__animated", "animate__fadeOut");
          hamburger.style.display = "block";
          hamburgerCancel.classList.remove("animate__animated", "animate__fadeIn");
          hamburgerCancel.style.display = "none";
          headerCall.style.display = "block";
  
          document.body.style.overflow = "auto";
        }
    }, []);

    const onText37Click = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='container']");
        if (anchor) {
            anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }

        const menu = document.querySelector(".menu");
        const hamburger = document.querySelector(".hamburger");
        const hamburgerCancel = document.querySelector(".hamburger-cancel");
        const headerCall = document.querySelector(".header-call");
  
        if (menu && hamburger && hamburgerCancel) {
          menu.classList.remove("animate__animated", "animate__fadeIn");
          menu.style.display = "none";
          hamburger.classList.remove("animate__animated", "animate__fadeOut");
          hamburger.style.display = "block";
          hamburgerCancel.classList.remove("animate__animated", "animate__fadeIn");
          hamburgerCancel.style.display = "none";
          headerCall.style.display = "block";
  
          document.body.style.overflow = "auto";
        }
    }, []);

    const onText38Click = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='container-number']");
        if (anchor) {
            anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }

        const menu = document.querySelector(".menu");
        const hamburger = document.querySelector(".hamburger");
        const hamburgerCancel = document.querySelector(".hamburger-cancel");
        const headerCall = document.querySelector(".header-call");
  
        if (menu && hamburger && hamburgerCancel) {
          menu.classList.remove("animate__animated", "animate__fadeIn");
          menu.style.display = "none";
          hamburger.classList.remove("animate__animated", "animate__fadeOut");
          hamburger.style.display = "block";
          hamburgerCancel.classList.remove("animate__animated", "animate__fadeIn");
          hamburgerCancel.style.display = "none";
          headerCall.style.display = "block";
  
          document.body.style.overflow = "auto";
        }
    }, []);

    const handleMenuOpen = useCallback(() => {
        const menu = document.querySelector(".menu");
        const hamburger = document.querySelector(".hamburger");
        const hamburgerCancel = document.querySelector(".hamburger-cancel");
        const headerCall = document.querySelector(".header-call");
  
        if (menu && hamburger && hamburgerCancel) {
          menu.classList.add("animate__animated", "animate__fadeIn");
          menu.style.display = "flex";
          hamburger.classList.add("animate__animated", "animate__fadeOut");
          hamburger.style.display = "none";
          hamburgerCancel.classList.add("animate__animated", "animate__fadeIn");
          hamburgerCancel.style.display = "block";
          headerCall.style.display = "none";
  
          document.body.style.overflow = "hidden";
        }
      }, []);
  
      const handleMenuClose = useCallback(() => {
        const menu = document.querySelector(".menu");
        const hamburger = document.querySelector(".hamburger");
        const hamburgerCancel = document.querySelector(".hamburger-cancel");
        const headerCall = document.querySelector(".header-call");
  
        if (menu && hamburger && hamburgerCancel) {
          menu.classList.remove("animate__animated", "animate__fadeIn");
          menu.style.display = "none";
          hamburger.classList.remove("animate__animated", "animate__fadeOut");
          hamburger.style.display = "block";
          hamburgerCancel.classList.remove("animate__animated", "animate__fadeIn");
          hamburgerCancel.style.display = "none";
          headerCall.style.display = "block";
  
          document.body.style.overflow = "auto";
        }
      }, []);

      const handleCopyClick = async () => {
        try {
          await navigator.clipboard.writeText("+998 99 128 53 27");
        } catch (error) {
          console.error("Error copying to clipboard:", error);
        }
  };
  

    return (
        <div className="relative w-[100%] h-[100vh] menu hidden">
            <div className="self-stretch bg-slateblue flex flex-col fixed top-[9%] left-[0] right-[0] bottom-[0] gap-[0] pt-20 pb-[20vh] items-start justify-between pb-[60.4px] pr-[119px] pl-[120px] box-border max-w-full gap-[20px] z-[2] text-left text-base text-white font-montserrat lg:flex-wrap mq450:pl-5 mq450:pr-5 mq450:box-border mq750:pl-[60px] mq750:pr-[59px] mq750:box-border">
                <div className="flex flex-col items-start justify-start max-w-full">
                    <img
                        className="self-stretch flex-1 relative w-[100%] sm:w-[90%] sm:max-w-full overflow-hidden sm:max-h-full"
                        alt=""
                        src="/menu-slogan.svg"
                        onClick={onLogoClick}
                    />
                </div>
                <div className="flex flex-col items-start justify-start py-0 pr-[76px] pl-0 box-border gap-[20px] text-sm sm:text-base max-w-full mq450:pr-5 mq450:box-border">
                    <div
                        className="relative leading-[18px] font-medium"
                        onClick={onText34Click}
                    >
                        Описание ярмарки
                    </div>
                    <div
                        className="relative leading-[18px] font-medium"
                        onClick={onText35Click}
                    >
                        Цели благотворительной ярмарки
                    </div>
                    <div
                        className="relative leading-[18px] font-medium"
                        onClick={onText36Click}
                    >
                        Герои благотворительной ярмарки
                    </div>
                    <div
                        className="relative leading-[18px] font-medium inline-block min-w-[116px]"
                        onClick={onText37Click}
                    >
                        Работы детей
                    </div>
                    <div
                        className="relative leading-[18px] font-medium inline-block min-w-[111px]"
                        onClick={onText38Click}
                    >
                        Мы в цифрах
                    </div>
                </div>
                <div className="w-[171px] flex flex-col items-start justify-start pt-[20px] sm:pt-[84px] px-0 pb-0 box-border text-center text-lgi">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[32px]">
                        <div className="self-stretch flex flex-row items-start justify-start py-0 pr-2.5 pl-px">
                            <div className="flex-1 flex flex-row items-start justify-between gap-[20px]">
              <a href="https://www.instagram.com/vremya.chudes?igsh=MWU1cGFpYzJjMzUycQ==" target="_blank" rel="noopener noreferrer">
              <img
                className="h-8 w-8 relative overflow-hidden shrink-0 min-h-[32px]"
                alt=""
                src="/instagram-1.svg"
              />
              </a>
              <a href="https://t.me/VremyaChudes" target="_blank" rel="noopener noreferrer">
              <img
                className="h-8 w-8 relative overflow-hidden shrink-0 min-h-[32px]"
                alt=""
                src="/telegram-1.svg"
              />
              </a>
              <a href="https://m.facebook.com/vremyachudes/" target="_blank" rel="noopener noreferrer">
              <img
                className="h-8 w-8 relative overflow-hidden shrink-0 min-h-[32px]"
                alt=""
                src="/facebook-1.svg"
              />
              </a>
                            </div>
                        </div>
                        <div className="flex-1 flex items-center justify-between gap-[10px] pb-10">
                            <b className="self-stretch relative leading-[22px] flex items-center whitespace-nowrap">
                              <a href="tel:+998991285327"  target="_blank" rel="noopener noreferrer" className="appearance-none no-underline" style={{color: "#fff"}}>
                                +998 99 128 53 27
                              </a>
                            </b>
                            <button className="flex items-center justify-center bg-transparent" onClick={handleCopyClick}>
                                <img className="w-7 overflow-hidden shrink-0" alt="" src="/copy.svg" />
                            </button>
                        </div>
                        <button
                            className="w-[90vw] xs:w-auto px-0 xs:px-20 p-3 rounded-lg flex items-center justify-center bg-white text-lg font-bold"
                            style={{ color: "#5D4FCA" }}
                        >
                          <a href="tel:+998991285327"  target="_blank" rel="noopener noreferrer" className="appearance-none no-underline" style={{color: "#5D4FCA"}}>
                            Позвонить
                          </a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hamburger;
