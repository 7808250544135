import React, { useCallback } from "react";

const Footer = () => {
  const onLogoClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameSection']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText34Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='section2']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText35Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='section1']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText36Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='container1']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText37Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='container']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText38Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='container-number']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <footer className="self-stretch bg-slateblue flex flex-col md:flex-row items-start justify-between pt-20 pb-[60.4px] pr-[119px] pl-[120px] box-border max-w-full gap-[20px] z-[2] text-left text-base text-white font-montserrat lg:flex-wrap mq450:pl-5 mq450:pr-5 mq450:box-border mq750:pl-[60px] mq750:pr-[59px] mq750:box-border">
      <div 
        className="flex flex-col items-start justify-start pb-3 gap-[12px] max-w-full cursor-pointer"
        onClick={onLogoClick}  
      >
        <div className="w-[60%] sm:w-[90%] self-stretch flex flex-row items-start justify-center py-0 mq450:box-border">
          <img
            className="h-[12px] sm:h-[16.9px] relative"
            alt=""
            src="/-1.svg"
          />
        </div>
        <img
          className="self-stretch flex-1 relative w-[60%] sm:w-[90%] sm:max-w-full overflow-hidden sm:max-h-full"
          alt=""
          src="/group-2-1.svg"
        />
      </div>
      <div className="flex flex-col items-start justify-start py-0 pr-[76px] pl-0 box-border gap-[20px] text-sm sm:text-base max-w-full mq450:pr-5 mq450:box-border">
        <div className="relative leading-[18px] font-medium cursor-pointer" onClick={onText34Click}>
          Description of the Fair
        </div>
        <div className="relative leading-[18px] font-medium cursor-pointer" onClick={onText35Click}>
          Objectives of the Charity Fair
        </div>
        <div className="relative leading-[18px] font-medium cursor-pointer" onClick={onText36Click}>
          Heroes of the Charity Fair
        </div>
        <div className="relative leading-[18px] font-medium inline-block min-w-[116px] cursor-pointer" onClick={onText37Click}>
          Children's works
        </div>
        <div className="relative leading-[18px] font-medium inline-block min-w-[111px] cursor-pointer" onClick={onText38Click}>
          We in Figures
        </div>
      </div>
      <div className="w-[171px] flex flex-col items-start justify-start pt-[20px] sm:pt-[84px] px-0 pb-0 box-border text-center text-lgi">
        <div className="self-stretch flex flex-col items-start justify-start gap-[32px]">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-2.5 pl-px">
            <div className="flex-1 flex flex-row items-start justify-between gap-[20px]">
              <a href="https://www.instagram.com/vremya.chudes?igsh=MWU1cGFpYzJjMzUycQ==" target="_blank" rel="noopener noreferrer">
              <img
                className="h-8 w-8 relative overflow-hidden shrink-0 min-h-[32px]"
                alt=""
                src="/instagram-1.svg"
              />
              </a>
              <a href="https://t.me/VremyaChudes" target="_blank" rel="noopener noreferrer">
              <img
                className="h-8 w-8 relative overflow-hidden shrink-0 min-h-[32px]"
                alt=""
                src="/telegram-1.svg"
              />
              </a>
              <a href="https://m.facebook.com/vremyachudes/" target="_blank" rel="noopener noreferrer">
              <img
                className="h-8 w-8 relative overflow-hidden shrink-0 min-h-[32px]"
                alt=""
                src="/facebook-1.svg"
              />
              </a>
            </div>
          </div>
          <b className="self-stretch relative leading-[22px] whitespace-nowrap">
            <a href="tel:+998991285327"  target="_blank" rel="noopener noreferrer" className="appearance-none no-underline" style={{color: "#fff"}}>
            +998 99 128 53 27
            </a>
          </b>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
