const Component1 = () => {
  return (
    <section
      className="self-stretch flex flex-col items-center justify-start pt-24 px-5 pb-[96.6px] box-border gap-[64px] max-w-full text-center text-9xl text-darkslategray font-montserrat mq450:gap-[16px] mq750:gap-[32px] mq750:pt-[62px] mq750:pb-[63px] mq750:box-border"
      data-scroll-to="section1"
    >
      <div className="w-[1200.5px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
        <h2 className="m-0 w-[531px] relative text-inherit leading-[36px] font-bold font-inherit inline-block mq450:text-3xl mq450:leading-[29px]">
          Цели благотворительной ярмарки
        </h2>
      </div>
      <div className="w-[1200.5px] grid sm:flex flex-row flex-wrap items-start gap-[32px] max-w-full text-left text-base mq750:gap-[16px]">
        <div className="w-[80%] sm:w-[257.3px] flex flex-row items-start justify-self-start justify-start pt-[72px] px-[19.4px] pb-[40.4px] box-border relative">
          <div className="w-full absolute  !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex  sm:items-center sm:justify-center z-[0]">
            <img
              className="overflow-hidden max-h-full max-w-full object-contain absolute left-[-3px] top-[0] sm:top-[6px] [transform:scale(1.054)]"
              loading="lazy"
              alt=""
              src="/group.svg"
            />
          </div>
          <div className="flex relative w-[80%] xs:w-[220px] sm:w-auto text-sm sm:text-base tracking-[-0.01em] leading-[26px] font-medium text-transparent !bg-clip-text [background:linear-gradient(#333,_#333),_linear-gradient(rgba(0,_0,_0,_0.4),_rgba(0,_0,_0,_0.4)),_rgba(93,_79,_202,_0.9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mix-blend-hard-light z-[1]">
            Привлечение внимания бизнеса, благотворительных и международных
            организаций, горожан и гостей столицы к проблемам детей с
            инвалидностью.
          </div>
        </div>
        <div className="h-[299.4px] w-[70%] sm:w-[256.4px] flex flex-row items-start justify-self-end justify-end py-[86px] px-[19.1px] box-border relative">
          <div className="absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex items-end sm:items-center justify-end sm:justify-center z-[0]">
            <img
              className="max-w-full overflow-hidden max-h-full object-contain absolute right-0 top-[6px] [transform:scale(1.055)]"
              alt=""
              src="/group-1@2x.png"
            />
          </div>
          <div className="flex w-[220px] sm:w-auto text-sm sm:text-base tracking-[-0.01em] leading-[26px] font-medium text-transparent !bg-clip-text [background:linear-gradient(#333,_#333),_linear-gradient(rgba(0,_0,_0,_0.4),_rgba(0,_0,_0,_0.4)),_rgba(93,_79,_202,_0.9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mix-blend-hard-light z-[1]">
            Поиск возможностей для реализации творческих инициатив детей и их
            социальной адаптации.
          </div>
        </div>
        <div className="w-[70%] sm:w-[307.3px] flex flex-row items-start justify-self-start justify-start pt-[65px] px-[19.4px] pb-[10%] box-border relative">
          <div className="w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex items-center justify-center z-[0]">
            <img
              className="max-w-full overflow-hidden max-h-full object-contain absolute left-[0px] top-[6px] [transform:scale(1.2)] sm:[transform:scale(1.038)]"
              alt=""
              src="/group-21.svg"
            />
          </div>
          <div className="flex w-[270px] px-4 pl-7 sm:w-auto text-sm sm:text-base tracking-[-0.01em] leading-[26px] font-medium text-transparent !bg-clip-text [background:linear-gradient(#333,_#333),_linear-gradient(rgba(0,_0,_0,_0.4),_rgba(0,_0,_0,_0.4)),_rgba(93,_79,_202,_0.9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mix-blend-hard-light z-[1]">
            Поддержка детей с ограниченными возможностями, ориентированная на
            социальную ответственность и адресную работу.
          </div>
        </div>
        <div className="w-[70%] sm:w-[257.3px] flex flex-row items-start justify-self-end justify-end pt-[105px] pb-[74.4px] pr-[18px] pl-[22.2px] box-border relative">
          <div className="absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex items-center justify-center z-[0]">
            <img
              className="max-w-full overflow-hidden max-h-full object-contain absolute right-0 top-[6px] [transform:scale(1.054)]"
              alt=""
              src="/group-3.svg"
            />
          </div>
          <div className="flex w-[210px] sm:w-auto text-sm sm:text-base tracking-[-0.01em] leading-[26px] font-medium text-transparent !bg-clip-text [background:linear-gradient(#333,_#333),_linear-gradient(rgba(0,_0,_0,_0.4),_rgba(0,_0,_0,_0.4)),_rgba(93,_79,_202,_0.9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mix-blend-hard-light z-[1]">
            Привитие полезных навыков детям и гостям мероприятия как
            неотъемлемая потребность.
          </div>
        </div>
      </div>
    </section>
  );
};

export default Component1;
