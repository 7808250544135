import { useState, useEffect } from "react";
import ReactPlayer from "react-player";

const FrameComponent3 = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 640); // Assuming 640px is the breakpoint for mobile screens
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  if (isMobile) {
    return (
      <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-base text-darkslategray font-montserrat">
        <div className="w-[1200px] flex flex-row items-end justify-center gap-[36px] max-w-full mq750:gap-[18px] mq1050:flex-wrap">
          <div className="w-[479px] flex flex-col items-start justify-start pt-0 px-0 pb-[42px] box-border min-w-[479px] min-h-[339px] max-w-full mq750:min-w-full mq1050:flex-1">
            <div className="self-stretch flex flex-col items-start justify-center max-w-full">
              <div className="flex flex-row w-[100%] items-start justify-center py-0 pb-2 mq450:box-border">
                <img
                  className="h-[15px]"
                  loading="lazy"
                  alt=""
                  src="/slogan.svg"
                />
              </div>
              <div className="flex flex-row w-[100%] items-start justify-center py-0 pb-2 mq450:box-border">
                <img
                  className="w-[80%] pb-8 overflow-hidden"
                  loading="lazy"
                  alt=""
                  src="/group-2.svg"
                />
              </div>
              <div 
                className="rounded-lg flex relative max-w-full pb-5 h-[190px] xs:h-[350px] item-center justify-center overflow-hidden"
              >
                <ReactPlayer
                  loading="lazy"
                  className="videoFrame"
                  url="/video.mp4"
                  light="/video.png"
                  playing // Autoplay the video
                  playIcon={<></>}
                  controls // Show video controls
                  height="100%"
                />
              </div>
              <div className="self-stretch relative leading-[24px] font-medium">
                <p className="m-0">
                  Handicraft fair for children and young people with special needs.
                </p>
                <p className="m-0">Held annually since 2003.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-base text-darkslategray font-montserrat">
        <div className="w-[1200px] flex flex-row items-end justify-center gap-[36px] max-w-full mq750:gap-[18px] mq1050:flex-wrap">
          <div className="w-[479px] flex flex-col items-start justify-start pt-0 px-0 pb-5 box-border max-w-full mq750:min-w-full mq1050:flex-1">
            <div className="self-stretch flex flex-col items-start justify-start gap-[24.3px] max-w-full">
              <div className="w-[100%] h-[200.9px] flex flex-col items-start justify-center xmd:justify-start gap-[12.1px] max-w-full">
                <div className="w-[100%] flex flex-row items-start justify-center xmd:justify-start xmd:pl-20 py-0 mq450:box-border">
                  <img
                    className="h-[16.9px] relative"
                    loading="lazy"
                    alt=""
                    src="/slogan.svg"
                  />
                </div>
                <img
                  className="self-stretch xmd:self-start flex-1 relative max-w-full xmd:max-[100%] overflow-hidden max-h-full"
                  loading="lazy"
                  alt=""
                  src="/group-2.svg"
                />
              </div>
              <div className="self-stretch relative leading-[24px] font-medium">
                <p className="m-0">
                  Handicraft fair for children and young people with special needs.
                </p>
                <p className="m-0">Held annually since 2003.</p>
              </div>
            </div>
          </div>
          <ReactPlayer
            loading="lazy"
            className="videoFrame rounded-lg h-[382px] flex-1 relative max-w-full pb-5 overflow-hidden min-w-[445px] mq750:min-w-full"
            url="/video.mp4"
            light="/video.png"
            playing // Autoplay the video
            controls // Show video controls
          />
        </div>
      </div>
    );
  }  
};

export default FrameComponent3;
