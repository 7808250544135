import Header from "../components/Header";
import Hamburger from "../components/Hamburger";
import FrameComponent3 from "../components/FrameComponent3";
import FrameComponent2 from "../components/FrameComponent2";
import FrameComponent from "../components/FrameComponent";
import Component2 from "../components/Component2";
import Component1 from "../components/Component1";
import Component from "../components/Component";
import Numbers from "../components/Numbers";
import Footer from "../components/Footer";


const Desktop = () => {
  return (
    <div className="w-full relative bg-gray overflow-hidden flex flex-col items-end justify-start leading-[normal] tracking-[normal]">
      <div className="self-stretch h-[796px] relative box-border hidden border-b-[1px] border-solid border-black" />
      <section
        className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-[93.9px] box-border gap-[56px] max-w-full mq450:pb-10 mq450:box-border mq750:gap-[28px] mq1050:pb-[61px] mq1050:box-border"
        data-scroll-to="frameSection"
        >
        <div className="self-stretch flex flex-col items-start justify-start gap-[56px] max-w-full mq750:gap-[28px]">
          <Header />
          <Hamburger />
          <FrameComponent3 />
        </div>
        <FrameComponent2 />
      </section>
      <img
        className="w-[587px] relative max-h-full overflow-hidden object-contain hidden max-w-full"
        alt=""
        src="/--2.svg"
      />
      <FrameComponent />
      <Component1 />
      <Component2 />
      <Numbers />
      <Component />
      <Footer />
    </div>
  );
};

export default Desktop;
