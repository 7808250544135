import { useState, useEffect } from "react";

const FrameComponent2 = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 640); // Assuming 640px is the breakpoint for mobile screens
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return (
    <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-5xl text-slateblue font-montserrat">
      <div className="w-[653.9px] flex flex-col items-end justify-start gap-[10px] max-w-full">
        <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
          <h3 className="m-0 w-[298px] relative text-inherit leading-[36px] font-bold font-inherit inline-block mq450:text-lgi mq450:leading-[29px]">
            Initiative Principle:
          </h3>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-center relative gap-[14.6px] max-w-full text-xl text-mediumvioletred">
          {isMobile ? (
            <img
              className="absolute top-[1%] left-[10%] w-[16px]"
              loading="lazy"
              alt=""
              src="/vector.svg"
            />
          ) : (
            <img
              className="h-[23.1px] w-[19.9px] relative"
              loading="lazy"
              alt=""
              src="/vector.svg"
            />
          )}
          <div className="w-[70%] sm:w-[auto] flex-1 flex flex-col items-start justify-start pt-1.5 px-0 pb-10 sm:pb-0 box-border min-w-[380px] max-w-full mq450:min-w-full">
            <i className="self-stretch h-16 relative tracking-[0.01em] leading-[32px] inline-block font-semibold mq450:text-base mq450:leading-[26px]">
              {isMobile ? (
                <>
                  <p className="m-0">GIVE A MAN A FISH,</p>
                  <p className="m-0 mb-2">HE WILL BE FULL FOR A DAY,</p>
                  <p className="m-0">TEACH HIM HOW TO FISH,</p>
                  <p className="m-0">HE WILL BE FULL FOR A LIFETIME!</p>
                </>
              ) : (
                <>
                  <p className="m-0">GIVE A MAN A FISH, HE WILL BE FULL FOR A DAY,</p>
                  <p className="m-0">TEACH HIM HOW TO FISH, HE WILL BE FULL FOR A LIFETIME!</p>
                </>
              )}
            </i>
          </div>
          {isMobile ? (
            <img
              className="absolute bottom-[-15%] right-[5%] w-[16px]"
              loading="lazy"
              alt=""
              src="/vector-1.svg"
            />
          ) : (
            <div className="flex flex-col items-start justify-start pt-[49px] px-0 pb-0">
              <img
                className="w-[19.9px] h-[23.1px] relative"
                alt=""
                src="/vector-1.svg"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FrameComponent2;
