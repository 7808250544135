import { Button } from "@mui/material";

const Component = () => {
  return (
    <section
      className="self-stretch h-[130vw] sm:h-[50vw] relative max-w-full text-center text-9xl text-darkslategray font-montserrat"
      data-scroll-to="section"
    >
      <img
        className="absolute top-[10%] left-[0] sm:left-[8%] w-[100%] sm:w-[63%] object-cover rounded-none sm:rounded-xl"
        alt=""
        src="/result-holder@2x.png"
      />
      <div className="absolute top-[47%] sm:top-[15%] md:top-[23%] left-[5%] sm:gap-3 md:gap-7 sm:left-[60%] rounded-3xl bg-white shadow-[0px_4px_16px_rgba(0,_0,_0,_0.16)] w-[90%] sm:w-[33%] flex flex-col items-center justify-center py-6 sm:py-3 md:py-8 px-5 box-border gap-[15px] sm:gap-[32px] max-w-full z-[1]">
        <h2 className="m-0 relative text-inherit leading-[36px] font-bold font-inherit inline-block text-3xl sm:text-lg md:text-9xl mq450:leading-[29px]">
          Contact us
        </h2>
        <div className="flex flex-col items-center justify-center gap-[24px] sm:gap-2 md:gap-[24px] text-slateblue">
          <div className="self-stretch flex flex-row items-start justify-between py-0 px-[56.5px] gap-[20px]">
            <a href="https://www.instagram.com/vremya.chudes?igsh=MWU1cGFpYzJjMzUycQ==" target="_blank" rel="noopener noreferrer">
            <img
              className="h-8 w-8 relative overflow-hidden shrink-0 min-h-[32px]"
              loading="lazy"
              alt=""
              src="/instagram.svg"
            />
              </a>
              <a href="https://t.me/VremyaChudes" target="_blank" rel="noopener noreferrer">
            <img
              className="h-8 w-8 relative overflow-hidden shrink-0 min-h-[32px]"
              loading="lazy"
              alt=""
              src="/telegram.svg"
            />
              </a>
              <a href="https://m.facebook.com/vremyachudes/" target="_blank" rel="noopener noreferrer">
            <img
              className="h-8 w-8 relative overflow-hidden shrink-0 min-h-[32px]"
              loading="lazy"
              alt=""
              src="/facebook.svg"
            />
              </a>
          </div>
          <div className="flex flex-col items-center justify-start gap-[16px] sm:gap-3 md:gap[16px]">
            <b className="self-stretch relative leading-[36px] whitespace-nowrap text-3xl sm:text-lg md:text-9xl mq450:leading-[29px]">
              <a href="tel:+998991285327"  target="_blank" rel="noopener noreferrer" className="appearance-none no-underline" style={{color: "#5D4FCA"}}>
              +998 99 128 53 27
              </a>
            </b>
            <Button
              className="self-stretch h-[42px]"
              disableElevation={true}
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "16",
                background: "#d906b3",
                borderRadius: "8px",
                "&:hover": { background: "#d906b3" },
                height: 42,
              }}
            >
              <a href="tel:+998991285327"  target="_blank" rel="noopener noreferrer" className="appearance-none no-underline" style={{color: "#fff"}}>
                Call
              </a>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Component;
